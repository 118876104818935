
import Vue from 'vue';
import { routesNames, getRoutePath } from '@/router'
import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import { IPartner } from '~/types/misc_data';

import MISC_DATA from '@/store/modules/MiscDataModule';
import globalAws from '@/mixins/globalAws';
import currentDomain from '@/mixins/currentDomain';

export default Vue.extend({
	name: 'OfficialPartners',

	components: { SectionHeading },

	mixins: [currentDomain],

	props: {
		noHeader: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			routesNames: routesNames,
			getRoutePath: getRoutePath,
			awsPath: globalAws,
		}
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		domain(): string {
			return this.$getDomain();
		},

		partners(): IPartner[] {
			const partners = this.MISC_DATA.partners as any;
			return partners.map((p: any) => {
				let width: number
				let height: number
				switch(p.route_name) {
					case routesNames.partner_obi:
						width = this.isMobile ? 112 * 0.85 : 112
						height = this.isMobile ? 26 * 0.85 : 26
						break
					case routesNames.partner_bellaflora:
						width = this.isMobile ? 142 * 0.85 : 142
						height = this.isMobile ? 39 * 0.85 : 39
						break
					case routesNames.partner_dehner:
						width = this.isMobile ? 122 * 0.85 : 122
						height = this.isMobile ? 51 * 0.85 : 51
						break
					case routesNames.partner_bauwelt:
						width = this.isMobile ? 142 * 0.85 : 142
						height = this.isMobile ? 37 * 0.85 : 37
						break
					case routesNames.partner_sconto:
						width = this.isMobile ? 132 * 0.85 : 132
						height = this.isMobile ? 50 * 0.85 : 50
						break
					case routesNames.partner_teubl:
						width = this.isMobile ? 132 * 0.85 : 132
						height = this.isMobile ? 59 * 0.85 : 59
						break
					case routesNames.partner_raiffeisen_lagerhaus:
						width = this.isMobile ? 132 * 0.85 : 132
						height = this.isMobile ? 51 * 0.85 : 51
						break
					case routesNames.partner_hornbach:
						width = this.isMobile ? 132 * 0.85 : 132
						height = this.isMobile ? 35 * 0.85 : 35
						break
					case routesNames.partner_olympia:
						width = this.isMobile ? 135 * 0.85 : 135
						height = this.isMobile ? 45 * 0.85 : 45
						break
					case routesNames.partner_momax:
						width = this.isMobile ? 135 * 0.85 : 135
						height = this.isMobile ? 45 * 0.85 : 45
						break
					default:
						width = this.isMobile ? 132 * 0.85 : 132
						height = this.isMobile ? 50 * 0.85 : 50
				}
				p.logo_width = width
				p.logo_height = height
				return p
			});
		},
	}
});
